import React, { useContext, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import GlobalContext from "../contexts/GlobalContext"
import { allLangs } from "../utils/configClient"

import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Box } from "../components/Core"
import { slugCat, slugSubCat, slugHome } from "../utils/slugs"

import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg3.jpg"

const Application = ({ data }) => {
  const appNodes = data.allPrismicApplication.nodes
  const pData = data.prismicApplicationPage?.data
  const { btn_home } = data.prismicCommon?.data

  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== allLangs.default) {
      gContext.setCurrentLang(allLangs.default)
    }
  }, [])

  return (
    <>
      <GatsbySeo
        title={pData.meta_title?.text}
        description={pData.meta_description?.text}
      />
      <PageWrapper>
        <Hero bgImg={pData?.cover_image.url || bgImg}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title variant="hero" color="light">
                  {pData?.title ? pData?.title.text : Application}
                </Title>

                <Box mt={4}>
                  <div className="d-flex my-3 justify-content-center">
                    <div className="text-white font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="text-white font-weight-bold"
                      >
                        {btn_home ? btn_home.text : "Home"}
                      </Link>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9">
                <Row>
                  {appNodes.length > 0 &&
                    appNodes.map(
                      (
                        { data: { title, photo, subcat_link, cat_link } },
                        i
                      ) => {
                        if (
                          subcat_link.uid &&
                          subcat_link?.document.data?.category.uid
                        ) {
                          return (
                            <Col lg="4" key={i} className="mb-4">
                              <Link
                                to={slugSubCat(
                                  subcat_link.uid,
                                  subcat_link.document.data.category.uid,
                                  gContext.currentLang
                                )}
                              >
                                <div>
                                  <Img
                                    fluid={photo.fluid}
                                    className="border border-dark"
                                    css={`
                                      &:hover {
                                        border: ${({ theme }) =>
                                          `1px solid ${theme.colors.primary} !important`};
                                      }
                                    `}
                                    alt=""
                                  />
                                </div>
                                <h6
                                  className="text-center mt-3"
                                  css={`
                                    &:hover {
                                      color: ${({ theme }) =>
                                        theme.colors.primary};
                                    }
                                  `}
                                >
                                  {title.text}
                                </h6>
                              </Link>
                            </Col>
                          )
                        } else if (cat_link.uid) {
                          return (
                            <Col lg="4" key={i} className="mb-4">
                              <Link
                                to={slugCat(cat_link.uid, gContext.currentLang)}
                              >
                                <div>
                                  <Img
                                    fluid={photo.fluid}
                                    className="border border-dark"
                                    css={`
                                      &:hover {
                                        border: ${({ theme }) =>
                                          `1px solid ${theme.colors.primary} !important`};
                                      }
                                    `}
                                    alt=""
                                  />
                                </div>
                                <h6
                                  className="text-center mt-3"
                                  css={`
                                    &:hover {
                                      color: ${({ theme }) =>
                                        theme.colors.primary};
                                    }
                                  `}
                                >
                                  {title.text}
                                </h6>
                              </Link>
                            </Col>
                          )
                        } else {
                          return (
                            <Col lg="4" key={i} className="mb-4">
                              <div>
                                <Img
                                  fluid={photo.fluid}
                                  className="border border-dark"
                                  alt=""
                                />
                              </div>
                              <h6 className="text-center mt-3">{title.text}</h6>
                            </Col>
                          )
                        }
                      }
                    )}
                </Row>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default Application

export const query = graphql`
  query($lang: String!) {
    prismicApplicationPage(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        cover_image {
          url
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }

    prismicCommon(lang: { eq: $lang }) {
      data {
        btn_home {
          text
        }
      }
    }
    allPrismicApplication(
      sort: { fields: data___order, order: ASC }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        data {
          title {
            text
          }
          photo {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
          subcat_link {
            uid
            document {
              ... on PrismicSubCategory {
                data {
                  category {
                    uid
                  }
                }
                uid
              }
            }
          }
          cat_link {
            uid
          }
        }
      }
    }
  }
`
